<template>
  <div class="my-second help bgcolor">
      <Head :title="$route.query.name" :show="true" ></Head>
      <div class="common-box help-box">
          <div class="detail-main">
                <div class="detail-main-title">
                    {{$route.query.name}}
                </div>
                <div class="detail-main-content" v-html="user.helpcenter[$route.query.index].context">

                </div>
                <!-- <div v-for="(item,index) in answerList" :key="index">
                    <div v-if="num==item.num">
                        <div  v-for="(tt,ii) in item.detaillist" :key="ii">
                            <div v-for="(ttt,ddd) in tt.text" :key="ddd" class="detail-main-content">{{ttt}}</div>
                            <div class="detail-main-imgs">
                                <img v-for="(it,id) in tt.imgList" :key="id" :src="it" alt="">
                            </div>
                        </div>
                    </div>
                </div> -->
          </div>
      </div>
  </div>
</template>

<script>
import {myinfo} from "@/common"
export default {
    name:"mine-help-detail",
    mixins:[myinfo],
    data(){
        return {
            num: this.$route.query.num,
            answerList:[
                {
                    num: "1",
                    detaillist:[
                        {
                            text: [
                                this.$t('help.onetxt1'),
                                this.$t('help.onetxt2')
                            ],
                            imgList:[
                                require("@/assets/images/help/1-1.png"),
                                require("@/assets/images/help/1-2.png"),
                            ],             
                        }
                        
                    ]
                    
                },
                {
                    num: "2",
                    detaillist:[
                        {
                            text: [
                                this.$t('help.twotxt1'),
                            ],
                            imgList:[
                                require("@/assets/images/help/2-1.png"),
                                require("@/assets/images/help/2-2.png"),
                            ]     
                        },
                        {
                            text: [
                                this.$t('help.twotxt2'),
                            ],
                            imgList:[
                                require("@/assets/images/help/2-3.png"),
                                require("@/assets/images/help/2-4.png"),
                            ]
                            
                        },
                        {
                            text: [
                                this.$t('help.twotxt3'),
                            ],
                            imgList:[
                                require("@/assets/images/help/2-5.png"),
                                require("@/assets/images/help/2-6.png"),
                            ]
                        },
                        {
                            text: [
                                this.$t('help.twotxt4'),
                            ],
                            imgList:[
                                require("@/assets/images/help/2-7.png"),
                            ]     
                        }
                    ]
                    
                },
                {
                    num: "3",
                    detaillist:[
                        {
                            text: [
                                this.$t('help.threetxt1'),
                                this.$t('help.threetxt2'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/3-1.png"),
                                require("@/assets/images/help/3-2.png"),
                            ],
                            
                        },
                        {
                            text: [
                                this.$t('help.threetxt3'),
                                this.$t('help.threetxt4'),
                                this.$t('help.threetxt5'),
                                this.$t('help.threetxt6'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/3-3.png"),
                                require("@/assets/images/help/3-4.png"),
                                require("@/assets/images/help/3-5.png"),
                                require("@/assets/images/help/3-6.png"),
                            ],
                        },
                        {
                            text: [
                                this.$t('help.threetxt7'),
                                this.$t('help.threetxt8'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/3-7.png"),
                                require("@/assets/images/help/3-8.png"),
                                require("@/assets/images/help/3-9.png"),
                            ],
                        }
                    ]
                },
                {
                    num: "4",
                    detaillist:[
                        {
                            text: [
                                this.$t('help.fourtxt1'),
                                this.$t('help.fourtxt2'),
                                this.$t('help.fourtxt3'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/4-1.png"),
                                require("@/assets/images/help/4-2.png"),
                                require("@/assets/images/help/4-3.png"),
                            ],
                        },
                        {
                            text: [
                                this.$t('help.fourtxt4'),
                                this.$t('help.fourtxt5'),
                                this.$t('help.fourtxt6'),
                                this.$t('help.fourtxt7'),
                                this.$t('help.fourtxt8'),
                                this.$t('help.fourtxt9'),
                                this.$t('help.fourtxt10'),
                                this.$t('help.fourtxt11'),
                                this.$t('help.fourtxt12')
                            ], 
                            imgList:[
                                require("@/assets/images/help/4-4.png"),
                                require("@/assets/images/help/4-5.png"),
                            ],
                        },
                        {
                            text: [
                                this.$t('help.fourtxt13'),
                                this.$t('help.fourtxt14'),
                                this.$t('help.fourtxt15'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/4-6.png"),
                                require("@/assets/images/help/4-7.png"),
                                require("@/assets/images/help/4-8.png"),
                            ],
                        },
                        {
                            text: [
                                this.$t('help.fourtxt16'),
                                this.$t('help.fourtxt17'),
                                this.$t('help.fourtxt18'),
                                this.$t('help.fourtxt19'),
                                this.$t('help.fourtxt20'),
                                this.$t('help.fourtxt21'),
                                this.$t('help.fourtxt22'),
                                this.$t('help.fourtxt23'),
                                this.$t('help.fourtxt24'),
                                this.$t('help.fourtxt25'),
                                this.$t('help.fourtxt26'),
                            ], 
                            imgList:[
                                require("@/assets/images/help/4-9.png"),
                                require("@/assets/images/help/4-10.png"),
                            ],
                        },
                    ]
                },
                {
                    num: "5",
                    detaillist:[
                        {
                            text: [
                                this.$t('help.five1'),
                                this.$t('help.five2'),
                            ],
                            imgList:[
                                require("@/assets/images/help/5-1.png"),
                                require("@/assets/images/help/5-2.png"),
                            ],             
                        },
                        {
                            text: [
                                this.$t('help.five3')
                            ]
                        }
                        
                    ]
                },
            ]
        }
    }
}
</script>